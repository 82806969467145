import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import Titlebar from "common/components/Titlebar";
import FAQToggle from "common/components/FAQs";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

const FAQPage = () => {


  const seoData = {
    title: 'Radiance Hair Studio FAQs | Your Hair Care Questions Answered',
    description: 'Find answers to common questions about hair care, treatments, and services at Radiance Hair Studio. Explore our FAQs page for expert advice.',
    keywords: ['FAQ']
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "@id": "https://www.radiancehairstudio.com/faq.php",
    "additionalType": "https://www.radiancehairstudio.com/faq.php",
    "alternateName": "Radiance Hair Studio",
    "description": "Find answers to common questions about hair care, treatments, and services at Radiance Hair Studio. Explore our FAQs page for expert advice.",
    "keywords": "FAQ, hair care questions, Radiance Hair Studio FAQs",
    "image": "https://www.radiancehairstudio.com/home/home-image-18.webp",
    "mainEntity": []
  };
  
  // const faqs = [
  //   {
  //     title: "Why shall I go for this replacement?",
  //     description:
  //       "There are three conditions when you must go for these methods – having a bald head, facing the loss of hair and looking for a style statement. In all the cases, apply this hair styling method includes wig pasting. International standard glue or both sided tapes are used for the method, and in each case, there remains no side effect at all for any Hair Replacement in Delhi.",
  //   },
  //   {
  //     title: "Is there any side effect?",
  //     description:
  //       "All the processes that are applied by Radiance are external and hence there remains no chance of side-effects. Few types of glue or tapes are used, but those are non-effecting for your skull.",
  //   },
  //   {
  //     title: "Can there be any hair-loss?",
  //     description:
  //       "There is absolutely no chance of hair loss for you while applying hair wigs in Delhi. The treatment that is done here at Radiance is done on your skull and out professionals avoids treating your existing hair’s root. So, no chances of hair loss remain.",
  //   },
  //   {
  //     title: "Can I change the style?",
  //     description:
  //       "In case of Bonding or any other type of hair wigs, you can easily change the applied hairstyle. When you reach Radiance, our professionals will be guiding you in this regards. You can apply colors on your hair, use different patches.",
  //   },
  // ];

  const faqs = [
    {
      title: "Why shall I go for this replacement?",
      description:
        "There are three conditions when you must go for these methods – having a bald head, facing the loss of hair and looking for a style statement. In all the cases, apply this hair styling method includes wig pasting. International standard glue or both sided tapes are used for the method, and in each case, there remains no side effect at all for any Hair Replacement in Delhi.",
    },
    {
      title: "Is there any side effect?",
      description:
        "All the processes that are applied by Radiance are external and hence there remains no chance of side-effects. Few types of glue or tapes are used, but those are non-effecting for your skull.",
    },
    {
      title: "Can there be any hair-loss?",
      description:
        "There is absolutely no chance of hair loss for you while applying hair wigs in Delhi. The treatment that is done here at Radiance is done on your skull and out professionals avoids treating your existing hair’s root. So, no chances of hair loss remain.",
    },
    {
      title: "Can I change the style?",
      description:
        "In case of Bonding or any other type of hair wigs, you can easily change the applied hairstyle. When you reach Radiance, our professionals will be guiding you in this regards. You can apply colors on your hair, use different patches.",
    },
  ];

  const faqs1 = [
    {
      title:
        "Why should I go for additional hair volumizer instead of volumizing products?",
      description:
        "Volumizing products or additional volumizer are personal choices of course. But with additional volumizer, one gets a long term better solution for hair thinning.",
    },
    {
      title:
        "What is the difference between a hair extension and hair volumizer?",
      description:
        "The hair extensions usually add length to your already existing hair. The job of hair volumizer is to add the extra hair to the scalp visible.",
    },
    {
      title: "How long the additional volumizer last for?",
      description:
        "The additional human hair volumizer can last you for all most a year with proper use and care.",
    },
    {
      title:
        "Does the volumizer cause any negative effect on already existing mane?",
      description:
        "Additional hair volumizer is usually hand tied to the roots of the existing hair. Thus the indulgence of any chemicals or products is removed. No harm is caused to your scalp or hair.",
    },
    {
      title: "Can I style my hair after adapting hair volumizer?",
      description:
        "One can indulge in any kind of styling, hair coloring, curling or straightening after hair additional hair volumizer. But of course, excessive use of anything can cause damage.",
    },
  ];

  const faqs2 = [
    {
      title: "How easy is to wear the wigs?",
      description:
        "At Radiance, we will be providing you the hair wig for man in Delhi that you can easily use like a cap. Wear it or open it as you wish.",
    },
    {
      title: "How much extra will I have to pay for the extra long hair?",
      description:
        "Usually, the pre-defined wig comes with 6 to 8 inches length and man does fit this hair length perfectly. So for males, there is no need to pay anything extra.",
    },
    {
      title: "Will one wig cover all the head?",
      description:
        "The hair wig for men we provide at Radiance is of four kinds – for front part and back part, baby hair and full perimeter hair. You can choose the right one that will cover your baldness.",
    },
    {
      title: "How natural the look will be?",
      description:
        "The look that you will generate is absolutely a natural one. None will be able to differentiate your natural hair and wig on your scalp, even when they give a closer look.",
    },
  ];

  const faqs3 = [
    {
      title: "Will I suffer any hair loss for the process?",
      description:
        "The first thing to be stated here is that it is an externally applied process. Here adhesive is applied on your skull that will not at all touch your natural hair. Hence, there remains no chance of hair loss.",
    },
    {
      title: "How quickly I can go for a change?",
      description:
        "You can even opt for a daily change in your looks. Style options you will get here is the different forms of patches and also in the form of hair colors. The flexibility of this type of hair is such that your daily style change will not also matter for it.",
    },
    {
      title: "Is there any side effect on the skull?",
      description:
        "The glue that is used in hair bonding in Delhi is of international standard and Radiance is having such good professionals in this matter that the least chance of side effects are also removed.",
    },
    {
      title:
        "I am having natural hair on my head. Can I still apply the method for my style?",
      description:
        "Definitely, you can. The binding is made from the skull and there is nothing that your natural hair has to do here. They will just be trimmed",
    },
  ];

  const faqs4 = [
    {
      title: "Will my natural hair be damaged?",
      description:
        "There is no external thing to be applied in this method – no glues, no tapes. Your natural hair will be weaved to give the new structure, based on which the elongation will remain. So, this is such a Non-Surgical Hair replacement process, where there is no risk at all.",
    },
    {
      title: "Is there any side-effect?",
      description:
        "The process is not only an external one, but it is the process where even no chemicals will be used too. So, there remains the least chance of side-effect for your head as well as for your natural hair.",
    },
    {
      title: "Can I change the style on daily basis?",
      description:
        "The elongations in hair weaving in Delhi will be attached with the structure with clips or both sided tapes. Those are easy to be opened and re-applied. So, no hassle is there to open the extensions on daily basis and re-wear those on the next day.",
    },
    {
      title: "Will I have to be careful from swimming or other things?",
      description:
        "The literal answer is no. The entire thing that you will be applying here is natural and hence there remains no possibility of any sort of damage",
    },
  ];

  const faqs5 = [
    {
      title: "Is the process painful?",
      description:
        "The literal answer to this question is – by No means. The extension application method of clip-in that we apply at Radiance is fully an external one and there is surgery into it. So, there is not even minute chance of pain in the entire process.",
    },
    {
      title: "Will I have to wear the extension all the time?",
      description:
        "Clip-in extensions are very much easy to be opened and re-worn. The important declaration here is that – you will not have to visit us at Radiance for the purpose of re-wearing your extension. So, open it up, when you feel and re-wear, as you need it.",
    },
    {
      title: "What is the life-span on Clip In extensions?",
      description:
        "As long as the clips remain intact – this retains with your usage style.However, we will guide you in the best way.",
    },
    {
      title: "Can I use shampoos?",
      description:
        "Yes, you can. The extensions are made of natural human hair and hence can be washed easily with shampoos.",
    },
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <Titlebar title="Frequently Asked Questions" />
          <div className="container pt-5">
            <div className="row">
              <div className="col-md-12">
                <div class="card mb-4 ">
                  <div class="card-body p-4">
                    <div class="faq-description">
                      <p className="fqa_subjead">Hair Extension</p>
                      <h4>FAQs of <a href="/hair-replacement-for-men.php" style={{ color: "#000"}}>Hair Replacement</a> for Men's</h4>
                      <p>
                        We know that it is not very easy to go for a complete
                        transformation process. Many questions might have come
                        to your mind while thinking to go for a hair
                        replacement. That’s why here we tried to answer some
                        them.
                      </p>
                      <FAQToggle faqs={faqs} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{marginBottom: '25px'}}>
              <div className="col-md-6">
                <div class="card">
                  <div class="card-body p-4">
                    
                    <div class="faq-description">
                      <p className="fqa_subjead">Hair Bonding</p>
                      <h4><a href="/hair-bonding-for-men.php" style={{ color: "#000"}}>Hair Bonding</a> FAQs</h4>
                      <p>
                        Here we tried to answer some relevant specific questions
                        frequently asked by customers about hair bonding. You
                        may find them relevant too.
                      </p>

                      <FAQToggle faqs={faqs3} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 equall">
                <div class="card">
                  <div class="card-body p-4">
                    <div class="faq-description">
                      <p className="fqa_subjead">Customized Wigs</p>
                      <h4>Customized Wigs FAQs</h4>
                      <p>
                        Here are some questions you might have faced while
                        thinking to go for a hair wig. We tried to make your
                        confusion clear by answering them at below.
                      </p>

                      <FAQToggle faqs={faqs2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div class="card mb-4 ">
                  <div class="card-body p-4">
                    <div class="faq-description">
                      <p className="fqa_subjead">Volumizer Hair Additions</p>
                      <h4>Volumizer Hair Additions FAQs</h4>
                      <h5>Your LOCKS UNLOCKED!</h5>
                      <p>
                        We hear your concern at RADIANCE. We understand the
                        queries that need to be answered before you step forward
                        to get your additional hair volumizer. Here are some
                        answers to your questions that might bother you!
                      </p>

                      <FAQToggle faqs={faqs1} />
                      <p>
                        Every one of us deserves the best for our mane. Experts
                        at RADIANCE offer you a free consultation and answer all
                        your questions along with the best suggestion for your
                        hair.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div class="card">
                  <div class="card-body p-4">
                  <div class="faq-description">
                      <p className="fqa_subjead">Hair Clipping</p>
                      <h4>Hair Clipping FAQs</h4>
                      <FAQToggle faqs={faqs5} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div class="card">
                  <div class="card-body p-4">
                    <div class="faq-description">
                      <p className="fqa_subjead">Hair Weaving</p>
                      <h4>Hair Weaving FAQs</h4>
                      <FAQToggle faqs={faqs4} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default FAQPage;